import { Box } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";

import { DeviceType, useGetDeviceType } from "./media";

const popupContext = createContext<ReturnType<typeof useProvidePopup>>(
  (null as unknown) as ReturnType<typeof useProvidePopup>
);

interface Position {
  top: number;
  left: number;
  height: number;
  width: number;
}

type ScaleArrayItem = { scale: number; min?: number };
type ScaleArray = [ScaleArrayItem, ScaleArrayItem, ScaleArrayItem, ScaleArrayItem];

const getScaleForDevice = (deviceType: DeviceType, array: ScaleArray) => {
  switch (deviceType) {
    case "mobile":
      return array[0];
    case "mobile-tablet":
      return array[1];
    case "tablet":
      return array[2];
    case "desktop":
      return array[3];
  }

  return { scale: 1 };
};

function useProvidePopup() {
  const [PopupContent, setPopupContent] = useState<null | React.ReactNode>();
  const [position, setPosition] = useState<null | Position>();
  const [opacity, setOpacity] = useState<number | null>();

  const deviceType = useGetDeviceType();

  const setContent = (
    children: React.ReactNode,
    pos: Position,
    scalesX: ScaleArray,
    scalesY: ScaleArray
  ) => {
    const scaleX = getScaleForDevice(deviceType, scalesX);
    const scaleY = getScaleForDevice(deviceType, scalesY);
    let newWidth = pos.width * scaleX.scale;
    if (scaleX.min && newWidth < scaleX.min) {
      newWidth = scaleX.min;
    }
    let newHeight = pos.height * scaleY.scale;
    if (scaleY.min && newHeight < scaleY.min) {
      newHeight = scaleY.min;
    }
    let newLeft = pos.left - (newWidth - pos.width) / 2;
    if (newLeft - 20 < 0) {
      newLeft = pos.left;
    }
    const oldRight = pos.left + pos.width;
    if (newLeft + newWidth + 10 > window.innerWidth) {
      newLeft = oldRight - newWidth - 10;
    }
    const newTop = pos.top - (newHeight - pos.height) / 2;
    setPosition({ width: newWidth, left: newLeft, height: newHeight, top: newTop });
    setPopupContent(children);
    setOpacity(0);
    setTimeout(() => {
      setOpacity(1);
    }, 20);
  };

  const resetContent = () => {
    setPopupContent(null);
    setPosition(null);
    setOpacity(0);
  };

  return {
    setContent,
    PopupContent,
    position,
    resetContent,
    opacity,
  };
}

export function PopupProvider({ children }: { children: React.ReactNode }) {
  const value = useProvidePopup();
  return (
    <popupContext.Provider value={value}>
      {value.position && (
        <Box
          position="absolute"
          top={value.position.top}
          left={value.position.left}
          height={value.position.height}
          width={value.position.width}
          zIndex={50}
          backgroundColor="transparent"
          transition="opacity 0.3s"
          opacity={value.opacity ?? 0}
          onMouseLeave={() => {
            value.resetContent();
          }}
        >
          {value.PopupContent}
        </Box>
      )}
      {children}
    </popupContext.Provider>
  );
}

export const usePopup = () => {
  return useContext(popupContext);
};
