import { useMediaQuery } from "react-responsive";

export type DeviceType = "mobile" | "mobile-tablet" | "tablet" | "desktop";

export const useGetDeviceType = (): DeviceType => {
  const isMobile = useMediaQuery({ maxWidth: "480px" });
  const isMobileTablet = useMediaQuery({ minWidth: "480px", maxWidth: "768px" });
  const isTablet = useMediaQuery({ minWidth: "768px", maxWidth: "992px" });
  const isDesktop = useMediaQuery({ minWidth: "992px" });
  if (isMobile) {
    return "mobile";
  }
  if (isMobileTablet) {
    return "mobile-tablet";
  }
  if (isTablet) {
    return "tablet";
  }
  if (isDesktop) {
    return "desktop";
  }
  return "desktop";
};
